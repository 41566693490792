import { Box, Typography, useMediaQuery } from '@mui/material';

import { urls } from '@/constants';
import {
  androidDownloadButton,
  glassLeft,
  glassRight,
  iosDownloadButton,
  lumiqWhiteLogoWatermark,
  qrCode,
} from '@/images';

import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalTitle } from './ModalTitle';

export const Welcome = ({ goToNextStep }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isAndroid = navigator.userAgent.toLowerCase().includes('android');

  return (
    <Box
      sx={[
        styles.formContainer,
        {
          background: ({ gradients }) => gradients.blueToAqua,
          color: 'white',
        },
      ]}>
      <Header Title={<Title />} logo={lumiqWhiteLogoWatermark} />
      {isMobile ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexGrow: 1,
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography variant="h5" textAlign={'center'} mb={3}>
              Download the mobile app
            </Typography>
            <Box
              component="img"
              src={isAndroid ? androidDownloadButton : iosDownloadButton}
              sx={{ width: 184 }}
              onClick={() => {
                window.open(urls.downloadApp);
              }}
            />
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box component="img" src={glassRight} sx={{ width: 136 }} />
            <Box component="img" src={glassLeft} sx={{ width: 136 }} />
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <Box component="img" src={glassRight} sx={{ width: 136 }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography variant="h5" gutterBottom textAlign={'center'}>
              Scan to download the mobile app
            </Typography>
            <Box component="img" src={qrCode} sx={{ width: 184 }} />
          </Box>
          <Box component="img" src={glassLeft} sx={{ width: 136 }} />
        </Box>
      )}

      <Footer handleNext={() => goToNextStep()} />
    </Box>
  );
};

const Title = () => (
  <ModalTitle variant="light">You&apos;re all set!</ModalTitle>
);
