import { Box, Typography } from '@mui/material';
import { useContext } from 'react';

import { Link } from '@/containers';

import { OnboardingFormStepsContext } from '../OnboardingForm';
import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalTitle } from './ModalTitle';

export const Disclaimer = ({ goToPrevStep, goToNextStep }) => {
  const formContext = useContext(OnboardingFormStepsContext);
  const { disclaimers } = formContext;

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} />
      <Box sx={styles.formPrimarySection}>
        {disclaimers.map((disclaimer, index) => (
          <Box key={index}>
            <DisclaimerBulletPoint text={disclaimer} />
          </Box>
        ))}
        <Box sx={{ flexDirection: 'row' }}>
          <Typography component="span">
            For all other designations,{' '}
            <Link inline onClick={() => window.Intercom?.('show')}>
              contact us
            </Link>{' '}
          </Typography>
          <Typography component="span">for further guidance.</Typography>
        </Box>
      </Box>
      <Footer
        handlePrev={() => goToPrevStep()}
        handleNext={() => goToNextStep()}
      />
    </Box>
  );
};

const Title = () => (
  <ModalTitle>
    LumiQ offers verifiable CPD in Canada and NASBA-certified CPE for US CPAs,
    along with various other designations worldwide.
  </ModalTitle>
);

const DisclaimerBulletPoint = ({ text }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mb: 1.5,
        pb: 1.5,
      }}>
      <Box>
        <Typography>{'\u2022'}</Typography>
      </Box>
      <Box ml={1}>
        <Typography dangerouslySetInnerHTML={{ __html: text }} />
      </Box>
    </Box>
  );
};
