import {
  createBrowserRouter,
  Navigate,
  RouterProvider as ReactRouterDomProvider,
} from 'react-router-dom';
import { RouterErrorElement } from 'src/containers/ErrorBoundary/RouterErrorElement';

import { Routes } from '@/constants';
import { SessionLoader } from '@/containers';
import {
  CertificateDownload,
  CPACanada,
  CPDRequirements,
  Discover,
  Episode,
  ForgotPassword,
  LearningPath,
  LearningPaths,
  Library,
  Login,
  MyLumiq,
  Pricing,
  Profile,
  Register,
  Search,
  Technical,
  Tracker,
} from '@/pages';

// TODO: is there a way we can reuse the routes config from constants/routes.js in here?
const router = createBrowserRouter([
  {
    path: Routes.ROOT,
    element: <SessionLoader />,
    errorElement: <RouterErrorElement />,
    children: [
      {
        index: true,
        element: <Navigate to={Routes.DISCOVER + window?.location?.search} />,
      },
      {
        path: Routes.MY_OFFERS,
        element: <Navigate to={Routes.PRICING} />,
      },
      {
        path: '*',
        element: <Navigate to={Routes.DISCOVER + window?.location?.search} />,
      },
      {
        path: Routes.DISCOVER,
        element: <Discover />,
      },
      {
        path: Routes.TECHNICAL,
        element: <Technical />,
      },
      {
        path: Routes.LEARNING_PATHS,
        element: <LearningPaths />,
      },
      {
        path: Routes.LEARNING_PATH,
        element: <LearningPath />,
      },
      {
        path: Routes.LIBRARY,
        element: <Library />,
      },
      {
        path: Routes.PROFILE,
        element: <Profile />,
      },
      {
        path: Routes.EPISODE,
        element: <Episode />,
      },
      {
        path: Routes.MY_LUMIQ,
        element: <MyLumiq />,
      },
      {
        path: Routes.SEARCH,
        element: <Search />,
      },
      {
        path: Routes.TRACKER,
        element: <Tracker />,
      },
      {
        path: Routes.CPD_REQUIREMENTS,
        element: <CPDRequirements />,
      },
      {
        path: Routes.CPA_CANADA,
        element: <CPACanada />,
      },
      {
        path: Routes.PRICING,
        element: <Pricing />,
      },
      {
        path: Routes.CERTIFICATE_DOWNLOAD,
        element: <CertificateDownload />,
      },
      // {
      //   path: Routes.LOGIN,
      //   element: <Login />,
      // },
      // {
      //   path: Routes.REGISTER,
      //   element: <Register />,
      // },
      // {
      //   path: Routes.FORGOT_PASSWORD,
      //   element: <ForgotPassword />,
      // },
    ],
  },
]);

export const Router = () => <ReactRouterDomProvider router={router} />;
