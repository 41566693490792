import { Box, Grid, Hidden, Typography } from '@mui/material';
import format from 'date-fns/format';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { Skeleton } from '@/components';
import { selectUserRegion, useFetchPlanQuery } from '@/store';

export const PlanInfo = () => {
  const { data: plan, isLoading } = useFetchPlanQuery();
  const region = useSelector(selectUserRegion);

  return (
    <Grid container spacing={4}>
      {isLoading || !plan ? (
        <>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rounded" height={160} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rounded" height={160} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <Box sx={styles.infoBoxContainer}>
              <Typography sx={styles.infoBoxTitle} variant="h3">
                Plan Type
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="infoBox" sx={styles.infoBoxText}>
                  {plan.planType}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={styles.infoBoxContainer}>
              <Typography sx={styles.infoBoxTitle} variant="h3">
                {plan.validUntil ? 'Valid Until' : 'Usage Remaining'}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {plan.validUntil && (
                  <Typography variant="infoBox" sx={styles.infoBoxText}>
                    {format(plan.validUntil, 'MM/dd/yyyy')}
                  </Typography>
                )}
                {!R.isNil(plan.creditsRemaining) && region === 'CAN' && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'center',
                    }}>
                    <Typography variant="infoBox" sx={styles.infoBoxText}>
                      {Math.floor(plan.creditsRemaining)}
                    </Typography>
                    <Typography variant="h5" sx={styles.subscript}>
                      /h
                    </Typography>
                    <Typography variant="infoBox" sx={styles.infoBoxText}>
                      {Math.round((plan.creditsRemaining % 1) * 60)}
                    </Typography>
                    <Typography variant="h5" sx={styles.subscript}>
                      <Hidden smDown>/mins</Hidden>
                      <Hidden smUp>/m</Hidden>
                    </Typography>
                  </Box>
                )}
                {!R.isNil(plan.creditsRemaining) && region === 'USA' && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'center',
                    }}>
                    <Typography variant="infoBox" sx={styles.infoBoxText}>
                      {plan.creditsRemaining}
                    </Typography>
                    <Typography variant="h5" sx={styles.subscript}>
                      credits
                    </Typography>
                  </Box>
                )}
                {!plan.validUntil && R.isNil(plan.creditsRemaining) && (
                  <Typography variant="infoBox" sx={styles.infoBoxText}>
                    Unlimited
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const styles = {
  infoBoxContainer: {
    borderRadius: 2,
    border: theme => `1px solid ${theme.palette.grayBrightMid}`,
    overflow: 'hidden',
  },
  infoBoxTitle: {
    color: 'white',
    textAlign: 'center',
    padding: 2,
    background: ({ gradients }) => gradients.blueToAqua,
  },
  infoBoxText: {
    background: ({ gradients }) => gradients.blueToAqua,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    margin: 2,
  },
  subscript: {
    fontWeight: 400,
    color: 'grayDark',
  },
};
