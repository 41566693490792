import { Button as MuiButton } from '@mui/material';

// TODO: add more variants as we port over more buttons
// color: primary, secondary, tealGradient, orangeGradient
// icon
// fullWidth: true, false
// skinny: true, false

// TODO discuss with designers, how overflow should be handled vs constant width

export const Variants = {
  PRIMARY: 'primary',
  PRIMARY_GRADIENT: 'primaryGradient',
  SECONDARY: 'secondary',
  DARK: 'dark',
  OUTLINED: 'outlined',
  WARNING: 'warning',
};

export const Button = ({
  onClick,
  label,
  variant = 'primary',
  startIcon = null,
  disabled,
  fullWidth = true,
  skinny = true,
  sx,
}) => (
  <MuiButton
    onClick={onClick}
    sx={[
      sx,
      styles.button,
      styles[variant],
      !fullWidth && { minWidth: 'fit-content' },
      !skinny && { height: 48 },
      !label && {
        '& .MuiButton-startIcon': {
          mr: 0,
        },
      },
    ]}
    variant={variant === 'outlined' ? 'outlined' : 'contained'}
    startIcon={startIcon}
    disableRipple
    disableElevation
    disabled={disabled}
    fullWidth={fullWidth}>
    {label}
  </MuiButton>
);

const styles = {
  button: theme => ({
    ...theme.typography.button1,
    height: 40,
    px: 2,
    textWrap: 'noWrap',
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      mr: 1,
      width: '100%',
    },
    '&:hover': {
      opacity: 0.8,
    },
    '&:disabled': {
      backgroundImage: 'none',
      backgroundColor: 'grayBrightLight',
    },
  }),
  primary: {
    '&:hover': {
      backgroundColor: 'bluePrimary',
    },
    backgroundColor: 'bluePrimary',
    color: 'white',
  },
  primaryGradient: {
    '&:hover': {
      background: theme => theme.gradients.teal,
    },
    background: theme => theme.gradients.teal,
    color: 'white',
  },
  secondary: {
    '&:hover': {
      backgroundColor: 'grayLight',
    },
    backgroundColor: 'grayLight',
    color: 'black',
  },
  dark: {
    '&:hover': {
      backgroundColor: 'blueDark',
    },
    backgroundColor: 'blueBlack',
    color: 'white',
  },
  warning: {
    '&:hover': {
      background: theme => theme.gradients.orange,
    },
    background: theme => theme.gradients.orange,
    color: 'white',
  },
  outlined: {
    '&:hover': {
      color: 'bluePrimary',
      borderColor: 'bluePrimary',
      opacity: 1,
    },
    color: 'blueBlack',
    borderColor: 'blueBlack',
  },
};
