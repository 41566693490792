import { format } from 'date-fns';

import { NodeEndpoints } from './node.constants';

export const fetchUserRegionRequest = () => ({
  url: NodeEndpoints.USER_REGION,
});

export const updateUserRegionRequest = ({ region }) => ({
  url: NodeEndpoints.UPDATE_USER_REGION,
  method: 'POST',
  body: { region },
});

export const fetchLastActiveChapter = () => ({
  url: NodeEndpoints.LAST_ACTIVE_CHAPTER,
});

export const fetchUserEpisodesRequest = () => ({
  url: NodeEndpoints.USER_EPISODES,
});

export const fetchEpisodeRecentChapterId = ({ episodeId }) => ({
  url: NodeEndpoints.EPISODE_RECENT_CHAPTER_ID,
  params: {
    episodeId,
  },
});

export const fetchChapterListenAllowed = ({ chapterId }) => ({
  url: NodeEndpoints.CHAPTER_LISTEN_ALLOWED,
  params: {
    chapterId,
  },
});

export const fetchChapterProgress = ({ episodeId }) => ({
  url: NodeEndpoints.CHAPTER_PROGRESS,
  params: {
    episodeId,
  },
});

export const saveChapterProgress = ({ event }) => ({
  url: NodeEndpoints.SAVE_CHAPTER_PROGRESS,
  method: 'POST',
  body: event,
});

export const fetchPlaybackSpeedOptions = () => ({
  url: NodeEndpoints.PLAYBACK_SPEED_OPTIONS,
});

export const fetchTriennialSummaryRequest = () => ({
  url: NodeEndpoints.TRIENNIAL_SUMMARY,
});

export const fetchCertificateDownloadUrlRequest = ({ certificateId }) => ({
  url: NodeEndpoints.GET_CPD_CERTIFICATE,
  params: {
    id: certificateId,
  },
});

export const fetchCertificateDownloadUrlsRequest = ({ fromDate, toDate }) => ({
  url: NodeEndpoints.GET_CPD_CERTIFICATES,
  params: {
    from_date: fromDate,
    to_date: toDate,
  },
});

export const fetchCertificateSummaryRequest = ({ fromDate, toDate }) => ({
  url: NodeEndpoints.GET_CERTIFICATE_SUMMARY,
  params: {
    from_date: fromDate,
    to_date: toDate,
  },
});

export const createCustomCPDRecordRequest = ({
  source,
  description,
  credit,
  isEthics,
  dateCompleted,
  fileName,
  fileSize,
  fileUri,
}) => ({
  url: NodeEndpoints.CREATE_CUSTOM_CPD_RECORD,
  method: 'POST',
  body: {
    source,
    description,
    hours: parseFloat(credit),
    ethic_hours: isEthics ? parseFloat(credit) : 0,
    date_completed: format(dateCompleted, 'yyyy-MM-dd'),
    file_name: fileName,
    file_size: fileSize,
    file_uri: fileUri,
  },
});

export const updateCustomCPDRecordRequest = ({
  id,
  source,
  description,
  credit,
  isEthics,
  dateCompleted,
  fileName,
  fileSize,
  fileUri,
}) => ({
  url: NodeEndpoints.UPDATE_CUSTOM_CPD_RECORD,
  method: 'POST',
  body: {
    id,
    source,
    description,
    hours: parseFloat(credit),
    ethic_hours: isEthics ? parseFloat(credit) : 0,
    date_completed: format(dateCompleted, 'yyyy-MM-dd'),
    file_name: fileName,
    file_size: fileSize,
    file_uri: fileUri,
  },
});

export const deleteCustomCPDRecordRequest = ({ pdHourId }) => ({
  url: NodeEndpoints.DELETE_CUSTOM_CPD_RECORD,
  method: 'POST',
  body: {
    id: pdHourId,
  },
});

export const uploadCPDRecordFileRequest = ({ formData }) => ({
  url: NodeEndpoints.UPLOAD_CPD_RECORD_FILE,
  method: 'POST',
  body: formData,
});

export const fetchUserQuizzes = () => ({
  url: NodeEndpoints.USER_QUIZZES,
});

export const submitQuiz = ({ quizId, answerId, region }) => ({
  url: NodeEndpoints.SUBMIT_QUIZ,
  method: 'POST',
  body: { quizId, answerId, nasba: region === 'USA' },
});

export const submitAssessment = ({ assessmentId, answers }) => ({
  url: NodeEndpoints.SUBMIT_ASSESSMENT,
  method: 'POST',
  body: { assessmentId, answers },
});

export const redeemEpisode = ({ episodeId }) => ({
  url: NodeEndpoints.REDEEM_EPISODE,
  method: 'POST',
  body: { episodeId },
});

export const bookmarkEpisode = ({ episodeId, bookmarked }) => ({
  url: NodeEndpoints.BOOKMARK_EPISODE,
  method: 'POST',
  body: { episodeId, bookmarked },
});

export const submitEpisodeFeedback = ({
  episodeId,
  feedback,
  episodePlayedDuration,
}) => ({
  url: NodeEndpoints.SUBMIT_EPISODE_FEEDBACK,
  method: 'POST',
  body: { episodeId, feedback, episodeProgress: episodePlayedDuration },
});

export const submitEpisodeEvaluation = ({ episodeId, evaluation }) => ({
  url: NodeEndpoints.SUBMIT_EPISODE_EVALUATION,
  method: 'POST',
  body: { episodeId, evaluation },
});

export const fetchIsProductFeedbackReady = () => ({
  url: NodeEndpoints.PRODUCT_FEEDBACK_READY,
  method: 'GET',
});

export const submitProductFeedback = ({ feedback }) => ({
  url: NodeEndpoints.SUBMIT_PRODUCT_FEEDBACK,
  method: 'POST',
  body: { feedback },
});

export const submitPurchaseFeedback = ({ heardAboutUs, otherText }) => ({
  url: NodeEndpoints.SUBMIT_PURCHASE_FEEDBACK,
  method: 'POST',
  body: {
    heardAboutUs,
    otherText,
  },
});
