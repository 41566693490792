import { Box, Button, Hidden, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { getRedirectUrl, urls } from '@/constants';
import { selectSessionUserId } from '@/store';
import { login, logout } from '@/utils';

import { ProfileMenu } from './components';

export const NavbarActions = () => {
  const userId = useSelector(selectSessionUserId);

  if (userId) {
    return (
      <Box>
        <ProfileMenu logout={logout} />
      </Box>
    );
  }

  return (
    <Hidden mdDown>
      <Box>
        <Button href={urls.whatsLumiq} target="_blank">
          <Box sx={styles.aboutUs}>
            <Typography sx={styles.aboutUsTitle}>About Us</Typography>
            <Typography sx={styles.aboutUsText}>What&apos;s LumiQ?</Typography>
          </Box>
        </Button>
        <Button onClick={login}>
          <Typography sx={styles.buttonText}>Login</Typography>
        </Button>
        <Button href={getRedirectUrl(urls.accounts.register)}>
          <Typography sx={styles.buttonText}>Signup</Typography>
        </Button>
      </Box>
    </Hidden>
  );
};

const styles = {
  aboutUs: {
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'none',
  },
  aboutUsTitle: theme => ({
    ...theme.typography.subtitle3,
    lineHeight: 'initial',
  }),
  aboutUsText: theme => ({
    ...theme.typography.subtitle2,
    color: 'white',
  }),
  buttonText: theme => ({
    ...theme.typography.h6,
    textAlign: 'center',
    textTransform: 'none',
  }),
};
