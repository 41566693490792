import { Box } from '@mui/material';

import { CAROUSEL_EPISODES_LIMIT } from '@/constants';
import { EpisodeCard, EpisodeCardSkeleton } from '@/containers';
import { EVENT_CONSTANTS } from '@/services';

import { Carousel } from '../Carousel';

export const EpisodeCardCarousel = ({
  title,
  uKey,
  episodes, // TODO unless we change how EpisodeCard works, this should just be an array of ids
  isLoading,
  ViewAllLink,
  Tab,
}) => {
  return (
    <Carousel title={title} uKey={uKey} ViewAllLink={ViewAllLink} Tab={Tab}>
      {isLoading ? (
        Array.from({ length: CAROUSEL_EPISODES_LIMIT }).map((_, idx) => (
          <Box key={idx} sx={styles.episodeCardContainer}>
            <EpisodeCardSkeleton includeText />
          </Box>
        ))
      ) : (
        <>
          {episodes.map(episode => (
            <Box key={episode.episodeId} sx={styles.episodeCardContainer}>
              <EpisodeCard
                episodeId={episode.episodeId}
                adjustWidth={false}
                referrer={{
                  page: EVENT_CONSTANTS.PAGE.DISCOVER,
                  carousel: uKey,
                }}
              />
            </Box>
          ))}
        </>
      )}
    </Carousel>
  );
};

const styles = {
  episodeCardContainer: {
    mr: {
      xs: 2,
      md: 3,
    },
  },
};
