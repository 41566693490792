import { ConfirmationNumberRounded } from '@mui/icons-material';
import { Box, Hidden, Typography, useMediaQuery } from '@mui/material';

import {
  Button,
  EpisodeCategoryPill,
  EthicsPill,
  PremiumPill,
} from '@/components';
import { Routes } from '@/constants';
import {
  BookmarkIcon,
  PlayArrowFilledIcon,
  PlayArrowOutlinedIcon,
  ShareIcon,
} from '@/icons';
import { createRouteWithParams } from '@/utils';

import { Link } from '../Link';

// TODO mobile view hiding button texts
export const EpisodeBanner = ({
  episode,
  onTrailer,
  onPlayNow,
  onShare,
  onRedeem,
  actions,
  toggleBookmark,
  isBookmarking,
  isBookmarked,
  isEpisodeEthics,
  isFreeTrialUser,
  isEpisodeRedeemRequired,
  categoryTags,
  disableTitleLink,
  referrer,
}) => {
  const isScreenBelowMd = useMediaQuery(theme => theme.breakpoints.down('md'));
  const isScreenBelowSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        color: 'text.white',
        bgcolor: 'blueBlack',
        backgroundImage: ({ gradients }) =>
          `${gradients.seriesCoverGeneral}, url(${episode.cover})`,
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        width: '100%',
        maxWidth: MAX_WIDTH,
        margin: '0 auto',
        minHeight: HEIGHT,
      }}>
      <Box sx={styles.container}>
        <Box sx={styles.topContainer}>
          <Box sx={{ flexGrow: 1 }}>
            <Link
              to={
                disableTitleLink
                  ? null
                  : createRouteWithParams(Routes.EPISODE, {
                      episodeId: episode.episodeId,
                    })
              }
              state={{ referrer }}
              color="inherit">
              <Typography variant="h2" gutterBottom>
                {episode.name}
              </Typography>
            </Link>
          </Box>
          <Box sx={styles.descriptionContainer}>
            <Box sx={{ maxWidth: '700px' }}>
              <Typography variant="subtitle1" gutterBottom>
                {episode.hook}
              </Typography>
            </Box>
          </Box>
          <Typography variant="caption">{episode.supplemental}</Typography>
          <Box sx={styles.pillContainer}>
            {isEpisodeEthics && (
              <Box sx={{ m: 0.5 }}>
                <EthicsPill
                  region={episode.region}
                  ethicsDuration={episode.totalEthics}
                  showDuration={false}
                />
              </Box>
            )}
            {isFreeTrialUser && episode.isPremium && (
              <Box sx={{ m: 0.5 }}>
                <PremiumPill />
              </Box>
            )}
            <Hidden smDown>
              {categoryTags?.map(cat => (
                <Box key={cat.id} sx={{ m: 0.5 }}>
                  <EpisodeCategoryPill label={cat.name} />
                </Box>
              ))}
            </Hidden>
          </Box>
        </Box>
        {actions || (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="warning"
              label="Preview"
              fullWidth={false}
              skinny={false}
              startIcon={<PlayArrowOutlinedIcon />}
              onClick={onTrailer}
              disabled={episode.isArchived}
            />
            {isEpisodeRedeemRequired ? (
              <Button
                label={
                  isScreenBelowSm
                    ? ''
                    : isScreenBelowMd
                      ? 'Redeem'
                      : 'Redeem Episode'
                }
                variant="dark"
                onClick={onRedeem}
                skinny={false}
                fullWidth={false}
                startIcon={<ConfirmationNumberRounded />}
              />
            ) : (
              <Button
                variant="dark"
                onClick={onPlayNow}
                disabled={episode.isArchived}
                label={isScreenBelowMd ? '' : 'Play Now'}
                skinny={false}
                fullWidth={false}
                startIcon={<PlayArrowFilledIcon />}
              />
            )}
            <Button
              variant="dark"
              onClick={toggleBookmark}
              disabled={episode.isArchived || isBookmarking}
              label={isScreenBelowMd ? '' : 'Bookmark'}
              skinny={false}
              fullWidth={false}
              startIcon={
                <BookmarkIcon sx={{ color: isBookmarked ? 'aqua' : 'white' }} />
              }
            />
            <Button
              variant="dark"
              onClick={onShare}
              disabled={episode.isArchived}
              label={isScreenBelowMd ? '' : 'Share'}
              skinny={false}
              fullWidth={false}
              startIcon={<ShareIcon />}
            />

            {!!episode.companyLogo && (
              <Hidden smDown>
                <Box sx={styles.logoContainer}>
                  <Box
                    component="img"
                    src={episode.companyLogo}
                    alt="company logo"
                    sx={styles.logoImage}
                  />
                </Box>
              </Hidden>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const HEIGHT = 260;
export const MAX_WIDTH = 1360;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    p: 3,
    width: '100%',
    minHeight: HEIGHT,
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  descriptionContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logoContainer: {
    ml: 'auto',
    mt: 'auto',
    position: 'relative',
  },
  logoImage: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: {
      xs: 50,
      md: 80,
    },
    width: {
      xs: 50,
      md: 80,
    },
    borderRadius: 1.5,
  },
  pillContainer: {
    mt: 1,
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
};
