import { log } from '../logger';
import * as LogEvents from './logging';

export * from './events';

export const AnalyticsService = Object.fromEntries(
  Object.entries(LogEvents).map(([key, value]) => [
    key,
    value instanceof Function
      ? (...args: any[]) => {
          try {
            // @ts-ignore
            return value(...args);
          } catch (e) {
            log(new Error(`Error in analytics event '${key}'`), e);
          }
        }
      : value,
  ]),
);
