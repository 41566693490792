import { Search as SearchIcon } from '@mui/icons-material';
import { Box, Input } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Routes } from '@/constants';
import { useDebouncedCallback } from '@/hooks';
import { uiActions } from '@/store';

export const SearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [initialized, setInitialized] = useState(false);
  const inputRef = useRef();

  const query = new URLSearchParams(location.search);
  const { searchTerm: urlSearchTerm = '' } = Object.fromEntries(query);

  useEffect(() => {
    // On page load, initialize inputRef from query params
    if (initialized || !inputRef.current) return;
    if (urlSearchTerm) {
      inputRef.current.value = urlSearchTerm;
    }
    setInitialized(true);
  }, [initialized, inputRef, urlSearchTerm]);

  const onSearch = e => {
    if (e.target.value) {
      navigate({
        pathname: Routes.SEARCH,
        search: `?searchTerm=${encodeURIComponent(e.target.value)}`,
      });
    } else {
      navigate({
        pathname: Routes.DISCOVER,
      });
    }
  };

  const debouncedSearch = useDebouncedCallback(onSearch, 500);

  return (
    <Box sx={styles.searchInputContainer}>
      <Input
        disableUnderline
        sx={styles.searchInput}
        startAdornment={
          <SearchIcon
            onClick={() => {
              dispatch(uiActions.closeSidebarDrawer());
              setTimeout(() => {
                inputRef.current?.focus();
              }, 0);
            }}
          />
        }
        onChange={debouncedSearch}
        onKeyDown={e => {
          if (e.key === 'Enter') onSearch(e);
        }}
        inputRef={inputRef}
        placeholder="Search Episodes"
      />
    </Box>
  );
};

const styles = {
  searchInputContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 280,
  },
  searchInput: theme => ({
    transition: '0.5s all ease',
    width: 20,
    borderRadius: 1,
    pl: 1,
    '&>svg': {
      mr: 1,
    },
    '&.Mui-focused': {
      backgroundColor: 'white',
      color: 'blueBlack',
      width: 280,
      '&>svg': {
        color: 'blueBlack',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&>svg': {
        transition: '0.5s all ease',
        color: 'white',
        cursor: 'pointer',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: 280,
      backgroundColor: 'white',
      color: 'blueBlack',
      '&.Mui-focused': {
        backgroundColor: 'white',
        color: 'blueBlack',
        width: 280,
        '&>svg': {
          color: 'blueBlack',
        },
      },
    },
  }),
};
