import { Grid, Skeleton, TextField, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@/components';
import { asciiRegex, emailRegex } from '@/constants';
import { AnalyticsService } from '@/services';
import {
  selectSessionUserId,
  useFetchUserProfileQuery,
  useUpdateUserProfileMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

const formKeys = ['firstName', 'lastName', 'companyName', 'jobTitle', 'email'];
const initialFormState = Object.fromEntries(formKeys.map(key => [key, '']));
const initialErrorsState = Object.fromEntries(formKeys.map(key => [key, null]));

export const UpdateUserInfoForm = () => {
  const userId = useSelector(selectSessionUserId);

  const {
    data: user,
    isLoading,
    isFetching,
  } = useFetchUserProfileQuery(userId ? undefined : skipToken);
  const [updateProfile] = useUpdateUserProfileMutation();

  const [form, setForm] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorsState);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isEditing && user && !isFetching) {
      setForm(R.map(val => val || '', user));
    }
  }, [isEditing, isFetching, user]);

  const handleChange = event => {
    setIsEditing(true);

    const { id, value } = event.target;

    setForm(prevState => ({
      ...prevState,
      [id]: value,
    }));

    setErrors(prevState => ({
      ...prevState,
      [id]: FORM_CONFIG[id].validator(value)
        ? null
        : FORM_CONFIG[id].errorMessage,
    }));
  };

  const resetForm = () => {
    setIsEditing(false);
    setForm(user);
    setErrors(initialErrorsState);
  };

  const submitForm = async () => {
    try {
      const formData = R.map(i => i.trim(), form);
      await updateProfile(formData).unwrap();

      SnackbarUtils.success('Profile updated');

      AnalyticsService.updateUserProfile({
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
      });
    } catch (error) {
      SnackbarUtils.error('Failed to update profile');
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3">User Info</Typography>
      </Grid>
      {isLoading ? (
        Array.from({ length: 4 }).map((_, index) => (
          <Grid key={index} item xs={12} md={6}>
            <Skeleton variant="rounded" height={60} />
          </Grid>
        ))
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <TextField
              id="firstName"
              label="First Name"
              variant="outlined"
              onChange={handleChange}
              value={form.firstName}
              fullWidth
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="lastName"
              label="Last Name"
              variant="outlined"
              onChange={handleChange}
              value={form.lastName}
              fullWidth
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="companyName"
              label="Company Name"
              variant="outlined"
              onChange={handleChange}
              value={form.companyName}
              fullWidth
              error={!!errors.companyName}
              helperText={errors.companyName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="jobTitle"
              label="Job Title"
              variant="outlined"
              onChange={handleChange}
              value={form.jobTitle}
              fullWidth
              error={!!errors.jobTitle}
              helperText={errors.jobTitle}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              onChange={handleChange}
              value={form.email}
              fullWidth
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4} justifyContent="flex-end" mb={1}>
              <Grid item xs={6} md={3}>
                <Button
                  disabled={!isEditing}
                  variant="secondary"
                  label="Cancel"
                  onClick={resetForm}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Button
                  disabled={!isEditing || !isFormValid(form)}
                  onClick={submitForm}
                  label="Save"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const isNameValid = name => name?.trim().length > 0 && asciiRegex.test(name);

const isEmailValid = email => emailRegex.test(email);

const FORM_CONFIG = {
  firstName: {
    validator: isNameValid,
    errorMessage: 'Please enter a valid name.',
  },
  lastName: {
    validator: isNameValid,
    errorMessage: 'Please enter a valid name.',
  },
  companyName: {
    validator: () => true,
    errorMessage: 'Please enter a valid company name.',
  },
  jobTitle: {
    validator: () => true,
    errorMessage: 'Please enter a valid job title.',
  },
  email: {
    validator: isEmailValid,
    errorMessage: 'Please enter a valid email address.',
  },
};

const isFormValid = form =>
  Object.keys(FORM_CONFIG).every(key => FORM_CONFIG[key].validator(form[key]));
