import { PostgrestEndpoints } from '@/requests';

import { postgrestApi } from '../postgrest-api';
import { setTokens } from '../session';
import { handleOnQueryStartedError } from '../store.utils';

type LoginPayload = {
  email: string;
  password: string;
};

type LoginResponse = {
  accessToken: string;
  refreshToken: string;
  userId: string;
};

type SendResetPasswordPayload = {
  email: string;
};

export const authApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: ({ email, password }) => ({
        url: PostgrestEndpoints.LOGIN,
        method: 'POST',
        body: {
          email,
          password,
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const {
            data: { accessToken, refreshToken },
          } = await queryFulfilled;

          await dispatch(setTokens({ accessToken, refreshToken }));
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
      transformResponse: (res: any) => {
        return {
          accessToken: res.access_token,
          refreshToken: res.refresh_token,
          userId: res.user.user_id,
        };
      },
    }),
    sendResetPassword: builder.mutation<any, SendResetPasswordPayload>({
      query: ({ email }) => ({
        url: PostgrestEndpoints.FORGOT_PASSWORD,
        method: 'POST',
        body: { email },
      }),
    }),
  }),
});

export const { useLoginMutation, useSendResetPasswordMutation } = authApi;
