import { Box, Typography } from '@mui/material';
import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectUserRegion,
  useFetchLearningPathsQuery,
  userEpisodesSelectors,
} from '@/store';

import { LearningPathsGrid } from './LearningPathsGrid';
import {
  calculateDurationLeft,
  getCategoryCopy,
  sortLearningPaths,
} from './utils';

export const LearningPaths = () => {
  const userRegion = useSelector(selectUserRegion);
  const userEpisodes = useSelector(userEpisodesSelectors.selectEntities);

  const {
    data: learningPaths,
    isLoading,
    isUninitialized,
  } = useFetchLearningPathsQuery({
    region: userRegion,
  });

  const lps = useMemo(() => {
    return R.pipe(
      lps => calculateDurationLeft({ lps, userEpisodes }),
      sortLearningPaths,
    )(learningPaths);
  }, [userEpisodes, learningPaths]);

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h1" gutterBottom>
          Learning Paths
        </Typography>
        <Typography variant="subtitle1">
          Achieve your goals with Learning Paths — a guided set of podcasts that
          will help you develop mastery of specific skills or topics.
        </Typography>
      </Box>
      {Object.keys(lps).map(key => (
        <Box mb={4} key={key}>
          <Typography variant="h3" gutterBottom>
            {getCategoryCopy({ category: key, userRegion })}
          </Typography>
          <LearningPathsGrid
            lps={lps[key]}
            isLoading={isLoading || isUninitialized}
          />
        </Box>
      ))}
    </Box>
  );
};
