import { ArrowBack } from '@mui/icons-material';
import { Box, Hidden, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Routes, urls } from '@/constants';
import {
  AppFooter,
  Link,
  OffersList,
  Perks,
  PersonalOffer,
  PlanOptions,
} from '@/containers';
import { lumiqBlackLogo } from '@/images';
import { selectSessionUserId, selectUserRegion, updateRegion } from '@/store';

export const Pricing = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const userId = useSelector(selectSessionUserId);
  const region = useSelector(selectUserRegion);

  const [isOnPersonalPlans, setIsOnPersonalPlans] = useState(region === 'USA');

  const { region: queryRegion } = Object.fromEntries(
    new URLSearchParams(location.search),
  );

  useEffect(() => {
    if (userId || typeof queryRegion !== 'string') return;

    const formattedQueryRegion = queryRegion.toUpperCase();

    if (['USA', 'CAN'].includes(formattedQueryRegion)) {
      dispatch(updateRegion({ region: formattedQueryRegion }));
      setIsOnPersonalPlans(formattedQueryRegion === 'USA');
    }
  }, [dispatch, userId, queryRegion]);

  return (
    <Box sx={{ bgcolor: 'grayBrightLight', pt: 4, px: 3 }}>
      <Hidden smDown>
        <Link sx={{ width: '100%' }} to={Routes.DISCOVER}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ArrowBack sx={{ mr: 1 }} />
            <Typography variant="h3">Back To Player</Typography>
          </Box>
        </Link>
      </Hidden>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          gap: 8,
        }}>
        <Box>
          <Box component={'img'} src={lumiqBlackLogo} sx={{ width: 280 }} />
          <Typography
            sx={{
              fontFamily: 'lato',
              fontWeight: 300,
              fontSize: { xs: 40, sm: 64 },
            }}>
            How Much {region === 'CAN' ? 'CPD' : 'CPE'} Do You Need?
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
          }}>
          {isOnPersonalPlans ? (
            <>
              <PersonalOffer />
              <OffersList />
            </>
          ) : (
            <PlanOptions onPersonalPlan={() => setIsOnPersonalPlans(true)} />
          )}
        </Box>
        {region !== 'USA' && (
          <Box>
            <Typography component="span">
              LumiQ is eligible for the Canada Training Credit, a refundable tax
              credit (up to $250) for eligible tuition and other fees paid for
              courses taken in 2020 forward. <br /> Please see the{' '}
            </Typography>
            <Link to={urls.ctcPage} target="_blank" inline={true}>
              <Typography component="span">CTC page</Typography>
            </Link>
            <Typography component="span"> for details.</Typography>
          </Box>
        )}
        <Perks />
      </Box>
      <AppFooter />
    </Box>
  );
};
